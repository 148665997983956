<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fa fa-plus"></i> เพิ่มโปรโมชัน</h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body bg-white">
          <validation-observer v-slot="{handleSubmit}" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(addHandler)">
              <form-promotion :item="item"/>
              <div class="float-right d-flex">
                <span><router-link :to="'/promotion'" class="nav-link text-underline"> กลับ</router-link></span>
                <!-- <b-button variant="secondary" @click="onBackHandler()"><i class="fa fa-arrow-left"></i> กลับ</b-button> -->
                <b-button variant="primary" type="submit" class="style_btn" v-if="permission.actionData.flag_insert"><i class="fa fa-check"></i> บันทึก</b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateUtils from '../../../util/dateUtils';
import formPromotion from './form.promotion';
export default {
  name: 'promotion-view-page-promotion-add',
  data () {
    return {
      permission:{
        appSlug: 'promotion',
        actionData: {}
      },
      item: {
        id: '',
        name: '',
        order_start: '',
        order_end: '',
        status: 0,
        created: '',
        updated: '',
        cteator: '',
        type: '',
        delivery_start: '',
        delivery_end: '',
        order_time_start: '',
        order_time_end: '',
        delivery_time_start: '',
        delivery_time_end: '',
      }
    }
  },
  methods: {
    async addHandler () {
      const params = JSON.parse(JSON.stringify(this.item));
      params.order_start = DateUtils.dateFormat(params.order_start, "YYYY-MM-DD");
      params.order_end = DateUtils.dateFormat(params.order_end, "YYYY-MM-DD");
      params.delivery_start = DateUtils.dateFormat(params.delivery_start, "YYYY-MM-DD");
      params.delivery_end = DateUtils.dateFormat(params.delivery_end, "YYYY-MM-DD");
      params.order_time_start = DateUtils.dateFormat(params.order_time_start, "HH:mm");
      params.order_time_end = DateUtils.dateFormat(params.order_time_end, "HH:mm");
      params.delivery_time_start = DateUtils.dateFormat(params.delivery_time_start, "HH:mm");
      params.delivery_time_end = DateUtils.dateFormat(params.delivery_time_end, "HH:mm");

      const result = await this.HttpServices.postData("/ab_promotion", params);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
          this.$router.push('/promotion');
        });
        this.$emit("successFuction", "");
      }else{
        this.AlertUtils.alert('warning', result.status.message);
      }
    },
    onBackHandler(){
      this.$router.push("/promotion");
    }
  },
  components: {
    formPromotion
  },
  async mounted() {
    await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
    this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
  },
}
</script>
