<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">ข้อมูลโปรโมชัน</h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="mb-2">
                <label class="form-label">ชื่อโปรโมชัน</label>
                <base-input name="name" placeholder="ชื่อโปรโมชัน" v-model="item.name" :rules="{required: true}"></base-input>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-2">
                <label class="form-label">เงื่อนไข</label>
                <Select2 v-model="item.type" :options="types" placeholder="เงื่อนไข" />
                <base-input name="type" v-model="item.type" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input>
              </div>
            </div>

            <div  class="col-md-6 mt-4" v-if="type == 1 || type == 3">
              <div class="mb-2">
                <label class="form-label">วันเวลาเริ่มต้นสั่งซื้อ</label>
                <div class="row">
                  <div class="col-md-6 form_date_padding_bt_15">
                    <date-picker  :format="'DD/MM/YYYY'"
                                  v-model="item.order_start"
                                  class="w-100"
                                  input-class="form-control"
                                  placeholder="วันที่เริ่มต้นสั่งซื้อ"
                                  :disabled-date="disableStartDate"
                                  :append-to-body="false"
                                  :popup-style="{left: 0}">
                    </date-picker>
                    <base-input name="order_start" :rules="{required: type==1||type==3}" v-model="order_start" class="custom-input-valid-datepicker"></base-input>
                  </div>
                  <div class="col-md-6 form_date_padding_bt_15">
                    <date-picker  :format="'HH:mm'"
                                  v-model="item.order_time_start"
                                  class="w-100"
                                  input-class="form-control"
                                  placeholder="เวลาเริ่มต้นสั่งซื้อ"
                                  :append-to-body="false"
                                  :popup-style="{left: 0}"
                                  type="time">
                    </date-picker>
                    <base-input name="order_time_start" :rules="{required: type==1||type==3}" v-model="order_time_start" class="custom-input-valid-datepicker"></base-input>
                  </div>
                </div>
              </div>
            </div>
            <div  class="col-md-6 mt-4"
                  v-if="type == 1 || type == 3">
              <div class="mb-2">
                <label class="form-label">วันเวลาสิ้นสุดสั่งซื้อ</label>
                <div class="row">
                  <div class="col-md-6 form_date_padding_bt_15">
                    <date-picker  :format="'DD/MM/YYYY'"
                                  v-model="item.order_end"
                                  class="w-100"
                                  input-class="form-control"
                                  placeholder="วันที่สิ้นสุดสั่งซื้อ"
                                  :disabled-date="disabledEndDate"
                                  :append-to-body="false"
                                  :popup-style="{left: 0}">
                      </date-picker>
                    <base-input name="order_end" :rules="{required: type==1||type==3}" v-model="order_end" class="custom-input-valid-datepicker"></base-input>
                  </div>
                  <div class="col-md-6 form_date_padding_bt_15">
                    <date-picker  :format="'HH:mm'"
                                  v-model="item.order_time_end"
                                  class="w-100"
                                  input-class="form-control"
                                  placeholder="เวลาสิ้นสุดสั่งซื้อ"
                                  :append-to-body="false"
                                  :popup-style="{left: 0}"
                                  type="time">
                    </date-picker>
                    <base-input name="order_time_end" :rules="{required: type==1||type==3}" v-model="order_time_end" class="custom-input-valid-datepicker"></base-input>
                  </div>
                </div>
              </div>
            </div>
            <div  class="col-md-6 mt-4"
                  v-if="type == 2 || type == 3">
              <div class="mb-2">
                <label class="form-label">วันที่เริ่มต้นส่งสินค้า</label>
                <div class="row">
                  <div class="col-md-6 form_date_padding_bt_15">
                    <date-picker  :format="'DD/MM/YYYY'"
                                  v-model="item.delivery_start"
                                  class="w-100"
                                  input-class="form-control"
                                  placeholder="วันที่เริ่มต้นส่งสินค้า"
                                  :disabled-date="disableStartDateDelivery"
                                  :append-to-body="false"
                                  :popup-style="{left: 0}">
                    </date-picker>
                    <base-input name="delivery_start" :rules="{required: type==2||type==3}" v-model="delivery_start" class="custom-input-valid-datepicker"></base-input>
                  </div>
                  <div class="col-md-6 form_date_padding_bt_15">
                    <date-picker  :format="'HH:mm'"
                                  v-model="item.delivery_time_start"
                                  class="w-100"
                                  input-class="form-control"
                                  placeholder="เวลาเริ่มต้นส่งสินค้า"
                                  :append-to-body="false"
                                  :popup-style="{left: 0}"
                                  type="time">
                    </date-picker>
                    <base-input name="delivery_time_start" :rules="{required: type==2||type==3}" v-model="delivery_time_start" class="custom-input-valid-datepicker"></base-input>
                  </div>
                </div>
              </div>
            </div>
            <div  class="col-md-6 mt-4"
                  v-if="type == 2 || type == 3">
              <div class="mb-2">
                <label class="form-label">วันที่สิ้นสุดส่งสินค้า</label>
                <div class="row">
                  <div class="col-md-6 form_date_padding_bt_15">
                    <date-picker  :format="'DD/MM/YYYY'"
                                  v-model="item.delivery_end"
                                  class="w-100"
                                  input-class="form-control"
                                  placeholder="วันที่สิ้นสุดส่งสินค้า"
                                  :disabled-date="disabledEndDateDelivery"
                                  :append-to-body="false"
                                  :popup-style="{left: 0}">
                    </date-picker>
                    <base-input name="delivery_end" :rules="{required: type==2||type==3}" v-model="delivery_end" class="custom-input-valid-datepicker"></base-input>
                  </div>
                  <div class="col-md-6 form_date_padding_bt_15">
                    <date-picker  :format="'HH:mm'"
                                  v-model="item.delivery_time_end"
                                  class="w-100"
                                  input-class="form-control"
                                  placeholder="เวลาสิ้นสุดส่งสินค้า"
                                  :append-to-body="false"
                                  :popup-style="{left: 0}"
                                  type="time">
                    </date-picker>
                    <base-input name="delivery_time_end" :rules="{required: type==2||type==3}" v-model="delivery_time_end" class="custom-input-valid-datepicker"></base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-4">
    </div>
  </div>
</template>
<script>
import Constants from '../../../util/constants';
export default {
  name: 'promotion-view-form-promotion',
  data () {
    return {
      types: [],
      status: [],
      type: '',
      order_start: '',
      order_end: '',
      order_time_start: '',
      order_time_end: '',
      delivery_start: '',
      delivery_end: '',
      delivery_time_start: '',
      delivery_time_end: '',
    }
  },
  props: ['item'],
  methods: {
    async getDataTypes () {
      this.types = Constants.promotion_types;
    },
    async getDataStatus () {
      this.status = Constants.promotion_status;
    },
    disableStartDate(date) {
      if(this.item.order_end){
        return date > this.item.order_end;
      }
      return false;
    },
    disabledEndDate(date){
      if(this.item.order_start){
        return date < this.item.order_start;
      }
      return false;
    },
    disableStartDateDelivery(date) {
      if(this.item.delivery_end){
        return date > this.item.delivery_end;
      }
      return false;
    },
    disabledEndDateDelivery(date){
      if(this.item.delivery_start){
        return date < this.item.delivery_start;
      }
      return false;
    },
  },
  watch: {
    'item.type' (newVal, oldVal) {
      this.type = newVal;
    },
    'item.order_start': {
      immediate: true,
      handler (newVal, oldVal) {
        if(newVal){
          this.order_start = ""+newVal;
        }else{
          this.order_start = "";
        }
      }
    },
    'item.order_end': {
      immediate: true,
      handler (newVal, oldVal) {
        if(newVal){
          this.order_end = ""+newVal;
        }else{
          this.order_end = "";
        }
      }
    },
    'item.order_time_start': {
      immediate: true,
      handler (newVal, oldVal) {
        if(newVal){
          this.order_time_start = ""+newVal;
        }else{
          this.order_time_start = "";
        }
      }
    },
    'item.order_time_end': {
      immediate: true,
      handler (newVal, oldVal) {
        if(newVal){
          this.order_time_end = ""+newVal;
        }else{
          this.order_time_end = "";
        }
      }
    },
    'item.delivery_start': {
      immediate: true,
      handler (newVal, oldVal) {
        if(newVal){
          this.delivery_start = ""+newVal;
        }else{
          this.delivery_start = "";
        }
      }
    },
    'item.delivery_end': {
      immediate: true,
      handler (newVal, oldVal) {
        if(newVal){
          this.delivery_end = ""+newVal;
        }else{
          this.delivery_end = "";
        }
      }
    },
    'item.delivery_time_start': {
      immediate: true,
      handler (newVal, oldVal) {
        if(newVal){
          this.delivery_time_start = ""+newVal;
        }else{
          this.delivery_time_start = "";
        }
      }
    },
    'item.delivery_time_end': {
      immediate: true,
      handler (newVal, oldVal) {
        if(newVal){
          this.delivery_time_end = ""+newVal;
        }else{
          this.delivery_time_end = "";
        }
      }
    }
  },
  mounted() {
    this.getDataTypes();
    this.getDataStatus();
  }
}
</script>
